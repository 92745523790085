import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { SellersService } from 'app/providers/api/services';
import { useApiMutation } from 'shared/hooks/useApiMutation';
import { useApiQuery } from 'shared/hooks/useApiQuery';
import { Seller, SellerGeneralInfo } from 'shared/types/Sellers';
import { convertToObjectSellerContactInfo } from 'shared/utils/helpers';

import useSellerPage from '../hooks/useSellerPage';

export default () => {
  const { sellerId } = useParams();
  const { refetchSellerInfo } = useSellerPage();
  const [generalInfo, setGeneralInfo] = useState<SellerGeneralInfo | null>(
    null,
  );
  const [sellerInfo, setSellerInfo] = useState<Seller | null>(null);
  const [contactInfo, setContactInfo] = useState<{
    [key: string]: string | null;
  } | null>(null);

  const { isLoading } = useApiQuery(
    ['getSellerGeneralInfo', sellerId],
    () => SellersService.fetchSellerGeneralInfo(sellerId),
    {
      onSuccess: (data) => {
        setGeneralInfo(data);
      },
      cacheTime: 0,
    },
  );

  useApiQuery(
    ['getSellerContactInfo', sellerId],
    () => SellersService.fetchSellerContactInfo(sellerId),
    {
      onSuccess: ({ data }) => {
        const convertedData = convertToObjectSellerContactInfo(data);
        setContactInfo(convertedData);
      },
    },
  );

  const { mutate: handleUpdateGeneralInfo } = useApiMutation(
    ['updateSellerGeneralInfo', sellerId],
    SellersService.updateSellerGeneralInfo,
    {
      onSuccess: (data) => {
        refetchSellerInfo();
        setGeneralInfo(data);
      },
    },
  );

  const { mutate: handleUpdateContactInfo } = useApiMutation(
    ['updateSellerContactInfo', sellerId],
    SellersService.updateSellerContactInfo,
    {
      onSuccess: (data) => {
        const updatedField = { [data.type]: data.value };
        setContactInfo((prevState) => ({ ...prevState, ...updatedField }));
      },
    },
  );

  useApiQuery(
    ['getSellerInfo', sellerId],
    () => SellersService.fetchSeller(sellerId),
    {
      onSuccess: (data) => {
        setSellerInfo(data);
      },
    },
  );

  const { mutate: handleUpdateProductOrdering } = useApiMutation(
    ['updateSellerProductOrdering', sellerId],
    SellersService.updateSeller,
    {
      onSuccess: (data) => {
        setSellerInfo((prevState) => ({ ...prevState, ...data }));
      },
    },
  );

  return {
    isLoading,
    sellerInfo,
    generalInfo,
    contactInfo,
    handleUpdateGeneralInfo,
    handleUpdateContactInfo,
    handleUpdateProductOrdering,
  };
};
