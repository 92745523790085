import { AppBar, Box } from '@mui/material';

import reopsLogo from 'shared/assets/svg/re-ops-logo-filled.svg';
import { headerHeightStyle } from 'shared/constants/styles';

import AccountMenu from './AccountMenu';

export const Header = () => {
  return (
    <>
      <Box height={headerHeightStyle} />
      <AppBar
        sx={{
          boxShadow: '0px 1px 12px rgba(0, 0, 0, 0.12)',
          bgcolor: 'common.white',
        }}
      >
        <Box
          height={headerHeightStyle}
          display={'flex'}
          justifyContent={'space-between'}
          pr="40px"
          pl="24px"
        >
          <img width="120" height="64" src={String(reopsLogo)} alt="Re-ops" />
          <Box display="flex" alignItems="center" gap="24px">
            <AccountMenu />
          </Box>
        </Box>
      </AppBar>
    </>
  );
};
