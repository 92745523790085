import { Box } from '@mui/material';
import { memo } from 'react';

import useSellerMarketingInfo from 'pages/SellerPage/hooks/useSellerMarketingInfo';
import { Loader } from 'shared/ui/Loader';

import { SpecialtiesInfoForm } from './SpecialtiesInfoForm';
import { StatesInfoForm } from './StatesInfoForm';

export const MarketingInfoPage = memo(() => {
  const {
    marketingStates,
    handleUpdateMarketingStates,
    handleUpdateMarketingTags,
    sellerId,
    marketingAllTags,
    marketingSellerTags,
    isLoading,
  } = useSellerMarketingInfo();

  const visibilityEditIcon = {
    '&:hover .editIconButton': {
      visibility: 'visible',
    },
  };
  return (
    <Box flex="1">
      <Box mt={1} display="flex" height="100%">
        {!isLoading ? (
          <>
            <Box
              p="16px"
              bgcolor="grey.300"
              borderRadius="12px 0 0 12px"
              flexBasis="50%"
              sx={visibilityEditIcon}
            >
              <SpecialtiesInfoForm
                sellerId={sellerId}
                marketingAllTags={marketingAllTags}
                marketingSellerTags={marketingSellerTags}
                handleUpdateMarketingTags={handleUpdateMarketingTags}
              />
            </Box>
            <Box
              p="16px"
              flexBasis="50%"
              borderRadius="0 12px 12px 0"
              border="1px solid"
              borderColor="grey.500"
              sx={visibilityEditIcon}
            >
              <StatesInfoForm
                marketingStates={marketingStates}
                handleUpdateMarketingStates={handleUpdateMarketingStates}
                sellerId={sellerId}
              />
            </Box>
          </>
        ) : (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Loader size={100} />
          </Box>
        )}
      </Box>
    </Box>
  );
});
