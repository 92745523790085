import { alpha, createTheme } from '@mui/material/styles';

import { AccordionTheme } from './components/Accordion';
import { Baseline } from './components/Baseline';

export const theme = createTheme({
  palette: {
    background: {
      default: '#e6eaee',
    },
    common: {
      black: '#000',
      white: '#fff',
    },
    primary: {
      main: '#3F6EB7',
      100: 'rgba(63, 110, 183, 0.10)',
      400: '#5492F4',
      500: '#5CA1FF',
    },
    secondary: {
      main: '#00000061',
    },
    success: {
      main: '#61C2A2',
    },
    error: {
      main: '#BF1B28',
    },
    blue: {
      500: '#2196F3',
      main: '#2196F3',
    },
    grey: {
      300: '#E9E8E84D',
      500: '#E9E8E8',
      600: '#7A7A7A',
    },
    text: {
      disabled: '#363636',
    },
  },
  typography: {
    fontFamily: `OpenSans, Poppins ,sans-serif`,
    button: {
      fontSize: '15px',
      letterSpacing: '0.46px',
      textTransform: 'capitalize',
    },
    openSans: {
      h6: {
        fontFamily: 'OpenSans',
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
      },
      h5: {
        fontFamily: 'OpenSans',
        fontSize: '24px',
        lineHeight: '32px',
        fontWeight: 400,
      },
      subtitle1: {
        fontFamily: 'OpenSans',
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: '0.15px',
      },
      subtitle2: {
        fontFamily: 'OpenSans',
        fontSize: '14px',
        lineHeight: '24px',
        fontWeight: 600,
        letterSpacing: '0.1px',
      },
      subtitle1Medium: {
        fontFamily: 'OpenSans',
        fontSize: '16px',
        lineHeight: '20px',
        fontWeight: 600,
        letterSpacing: '0.15px',
      },
      body1: {
        fontFamily: 'OpenSans',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400,
      },
      body2: {
        fontFamily: 'OpenSans',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.25px',
      },
      body2Medium: {
        fontFamily: 'OpenSans',
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 600,
        letterSpacing: '0.1px',
      },
      button: {
        fontFamily: 'OpenSans',
        fontSize: '15px',
        lineHeight: '26px',
        fontWeight: 600,
        letterSpacing: '0.46px',
      },
      caption: {
        fontFamily: 'OpenSans',
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: 400,
        letterSpacing: '0.4px',
      },
      captionMedium: {
        fontFamily: 'OpenSans',
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: 600,
        letterSpacing: '0.2px',
      },
    },
    poppins: {
      h5: {
        fontFamily: 'Poppins',
        fontSize: '24px',
        lineHeight: '32px',
        fontWeight: 600,
      },
      subtitle1: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: '0.15px',
        fontWeight: 600,
      },
      subtitle2: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.1px',
        fontWeight: 600,
      },
      body2: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0.25px',
      },
    },
  },
  components: {
    MuiCssBaseline: Baseline,
    MuiCheckbox: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-checked': {
            color: theme.palette.primary.main,
          },
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '8px 22px',
          fontSize: '15px',
          letterSpacing: '0.46px',
          fontWeight: 600,
          textTransform: 'capitalize',
        },
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            height: '30px',
            padding: '8px 10px',
          },
        },
      ],
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-disabled': {
            color: alpha(theme.palette.common?.black, 0.3),
            TextFillColor: alpha(theme.palette.common?.black, 0.3),
          },
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-disabled>input': {
            color: alpha(theme.palette.common?.black, 0.3),
            TextFillColor: alpha(theme.palette.common?.black, 0.3),
          },
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    ...AccordionTheme,
  },
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 768,
      laptop: 1024,
      desktop: 1200,
    },
  },
});
