import { Box, Typography } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from 'shared/ui/Loader';

import { AddressInfoForm } from './AddressInfoForm';
import { ContactInfoForm } from './ContactInfoForm';
import { GeneralInfoForm } from './GeneralInfoForm';
import { ProductOrderingForm } from './ProductOrderingForm';

import useSellerGeneralInfo from '../../hooks/useSellerGeneralInfo';

export const CompanyInfoPage = memo(() => {
  const { t } = useTranslation();
  const {
    isLoading,
    generalInfo,
    handleUpdateGeneralInfo,
    contactInfo,
    handleUpdateContactInfo,
    sellerInfo,
    handleUpdateProductOrdering,
  } = useSellerGeneralInfo();
  return (
    <Box flex="1">
      <Box mt={1} display="flex" height="100%">
        {!isLoading ? (
          <>
            <Box
              p="16px"
              bgcolor="grey.300"
              borderRadius="12px 0 0 12px"
              flexBasis="50%"
            >
              <Typography mt="4px" typography="poppins.subtitle1">
                {t(`General Info`)}
              </Typography>
              <GeneralInfoForm
                generalInfo={generalInfo}
                handleUpdateGeneralInfo={handleUpdateGeneralInfo}
              />
            </Box>
            <Box
              p="16px"
              flexBasis="50%"
              borderRadius="0 12px 12px 0"
              border="1px solid"
              borderColor="grey.500"
            >
              <Typography mt="4px" typography="poppins.subtitle1">
                {t(`Contact Info`)}
              </Typography>
              <ContactInfoForm
                contactInfo={contactInfo}
                handleUpdateContactInfo={handleUpdateContactInfo}
              />
              <Typography mt={3} typography="poppins.subtitle1">
                {t(`Product Ordering`)}
              </Typography>
              <ProductOrderingForm
                sellerInfo={sellerInfo}
                handleUpdateProductOrdering={handleUpdateProductOrdering}
              />
              <Typography mt={3} typography="poppins.subtitle1">
                {t(`Address`)}
              </Typography>
              <AddressInfoForm
                contactInfo={contactInfo}
                handleUpdateContactInfo={handleUpdateContactInfo}
              />
            </Box>
          </>
        ) : (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Loader size={100} />
          </Box>
        )}
      </Box>
    </Box>
  );
});
