import { Suspense, memo, useCallback } from 'react';
import { Route, Routes } from 'react-router-dom';

import { AppRoutesProps } from 'shared/types/router';

import { RequireAuth } from './RequireAuth';

import { routeConfig } from '../config/routeConfig';

type RenderWithWrapperParams = [string, AppRoutesProps];

const AppRouter = () => {
  const renderWithWrapper = useCallback(
    ([routeName, route]: RenderWithWrapperParams) => {
      const element = <Suspense fallback={<div />}>{route.element}</Suspense>;

      return (
        // @ts-ignore
        <Route
          key={routeName}
          path={route?.path}
          index={route?.index}
          element={
            route.authOnly ? (
              <RequireAuth roles={route?.roles}>{element}</RequireAuth>
            ) : (
              element
            )
          }
        >
          {route.nested
            ? Object.entries(route.nested).map(renderWithWrapper)
            : null}
        </Route>
      );
    },
    [],
  );

  return <Routes>{Object.entries(routeConfig).map(renderWithWrapper)}</Routes>;
};

export default memo(AppRouter);
