import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from '@mui/material';

import { listOfStates } from 'shared/constants/states';
import { Modal } from 'shared/ui/Modal';

interface EditMarketingInputModalProps {
  titleModal: string;
  subTitleModal?: string;
  isModalOpen: boolean;
  handleCloseModal?: () => void;
  handleSubmitModal?: () => void;
  approveNameButton: string;
  isSelect?: boolean;
  error?: boolean | undefined;
  helperText?: string;
  handleChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string[] | boolean,
  ) => void;
  handleSelectAll?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
  selectedOptions?: string[];
  allOptions?: string[];
  setSelectedOptions?: (arg0: string[]) => void;
}

export const EditMarketingInputModal = ({
  titleModal,
  subTitleModal,
  isModalOpen,
  handleCloseModal,
  handleSubmitModal,
  handleChange,
  handleSelectAll,
  approveNameButton,
  isSelect,
  error,
  selectedOptions,
  allOptions,
}: EditMarketingInputModalProps) => {
  return (
    <Modal
      disabled={error}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      handleSubmitModal={handleSubmitModal}
      approveNameButton={approveNameButton}
    >
      <Box sx={{ width: 400 }}>
        <Typography
          sx={{
            fontFamily: 'OpenSans',
            fontSize: '16px',
            lineHeight: '20px',
            fontWeight: 700,
            letterSpacing: '0.14px',
          }}
        >
          {titleModal}
        </Typography>
        {subTitleModal && (
          <Typography
            sx={{
              marginY: '24px',
              fontFamily: 'OpenSans',
              fontSize: '15px',
              lineHeight: '20px',
              fontWeight: 400,
              letterSpacing: '0.14px',
            }}
          >
            {subTitleModal}
          </Typography>
        )}
        {isSelect ? (
          <FormControl fullWidth sx={{ marginY: '10px' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '1px solid #00000030',
              }}
            >
              <Typography typography="openSans.caption" color="#000000DE">
                {`States`}
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedOptions?.length === listOfStates?.length}
                    onChange={handleSelectAll}
                  />
                }
                label="Select all"
              />
            </Box>
            <FormGroup
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                height: '360px',
                width: '400px',
                overflow: 'auto',
                borderBottom: '1px solid #00000030',
              }}
            >
              {listOfStates.map(({ label, value }) => (
                <Box key={value} sx={{ width: '50%' }}>
                  <FormControlLabel
                    componentsProps={{
                      typography: {
                        fontSize: '14px',
                      },
                    }}
                    control={
                      <Checkbox
                        sx={{ padding: '6px 9px' }}
                        checked={selectedOptions?.includes(value)}
                        onChange={handleChange}
                        value={value}
                      />
                    }
                    label={label}
                  />
                </Box>
              ))}
            </FormGroup>
          </FormControl>
        ) : (
          <Autocomplete
            sx={{ marginTop: '20px' }}
            multiple
            id="tags-outlined"
            options={allOptions}
            getOptionLabel={(option) => option}
            defaultValue={selectedOptions}
            filterSelectedOptions
            onChange={handleChange}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={index}
                  label={option}
                  {...getTagProps({ index })}
                  sx={{
                    backgroundColor: '#267C9B26',
                    color: '#267C9B',
                    '& .MuiChip-deleteIcon': {
                      color: '#267C9B',
                    },
                  }}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Specialties"
                placeholder="Add specialties"
                autoFocus
              />
            )}
          />
        )}
      </Box>
    </Modal>
  );
};
