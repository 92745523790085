import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { SellersService } from 'app/providers/api/services';
import { useApiMutation } from 'shared/hooks/useApiMutation';
import { useApiQuery } from 'shared/hooks/useApiQuery';

export default () => {
  const { sellerId } = useParams();
  const [marketingStates, setMarketingStates] = useState<string[] | null>(null);
  const [marketingAllTags, setMarketingAllTags] = useState<string[] | null>(
    null,
  );
  const [marketingSellerTags, setMarketingSellerTags] = useState<
    string[] | null
  >(null);

  const { isLoading } = useApiQuery(
    ['getSellerMarketingTags', sellerId],
    () => SellersService.fetchSellerMarketingTags(sellerId),
    {
      onSuccess: ({ data }) => {
        const sellerTags = data?.map((tag) => tag.title);
        setMarketingSellerTags(sellerTags);
      },
      cacheTime: 0,
    },
  );

  useApiQuery(
    ['getSellersMarketingStates', sellerId],
    () => SellersService.fetchSellerMarketingStates(sellerId),
    {
      onSuccess: ({ data }) => {
        setMarketingStates(data);
      },
    },
  );

  useApiQuery(
    ['getSellersMarketingAllTags'],
    () => SellersService.fetchSellersMarketingAllTags(),
    {
      onSuccess: ({ data }) => {
        const allTags = data.map((tag) => tag.title);
        setMarketingAllTags(allTags);
      },
    },
  );

  const { mutate: handleUpdateMarketingStates } = useApiMutation(
    ['updateSellerMarketingStates', sellerId],
    SellersService.updateSellerMarketingStates,
    {
      onSuccess: ({ data }) => {
        setMarketingStates(data);
      },
    },
  );

  const { mutate: handleUpdateMarketingTags } = useApiMutation(
    ['updateSellerMarketingtags', sellerId],
    SellersService.updateSellerMarketingTags,
    {
      onSuccess: ({ data }) => {
        const sellerTags = data.map((tag) => tag.title);
        setMarketingSellerTags(sellerTags);
      },
    },
  );

  return {
    sellerId,
    isLoading,
    marketingStates,
    marketingAllTags,
    marketingSellerTags,
    handleUpdateMarketingStates,
    handleUpdateMarketingTags,
  };
};
