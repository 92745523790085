import { Logout } from '@mui/icons-material';
import { Box, List, ListItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getRouteMain } from 'shared/constants/routerConst';
import useAuth from 'shared/hooks/useAuth';
import { VersionInfo } from 'shared/ui/VersionInfo';

const listItemStyles = {
  sx: {
    p: 0,
    ':hover': {
      bgcolor: 'action.hover',
      cursor: 'pointer',
    },
  },
};

const listItemContenStyles = {
  width: '100%',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  p: '0 16px',
};

export const AccountMenuItems = () => {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const navigate = useNavigate();

  const onLogout = () => {
    logout();
    navigate(getRouteMain());
  };

  return (
    <List
      sx={{
        padding: 0,
      }}
    >
      <ListItem onClick={onLogout} {...listItemStyles}>
        <Box {...listItemContenStyles}>
          <Logout color="action" />
          <Typography flex="1" fontSize="16px" ml="12px">
            {t('Sign Out')}
          </Typography>
        </Box>
      </ListItem>
      <Typography
        flex="1"
        align="right"
        style={{
          borderTop: '1px solid #e0e0e0',
          paddingTop: '8px',
          paddingRight: '10px',
          opacity: '0.5',
          fontSize: '12px',
        }}
        typography="openSans.caption"
      >
        <VersionInfo />
      </Typography>
    </List>
  );
};
