import React from 'react';

import { AuthProvider as Provider } from './AuthContext';
import useProvideAuth from './UseProvideAuth';

type AuthProviderProps = {
  children: JSX.Element;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const auth = useProvideAuth();

  return <Provider value={auth}>{children}</Provider>;
};
