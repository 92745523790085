/* eslint-disable max-len */
import {
  AttachFileOutlined,
  CircleRounded,
  LaunchOutlined,
  ModeEditOutlineOutlined,
} from '@mui/icons-material';
import { Box, IconButton, Link, Typography } from '@mui/material';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useSellerPolicyInfo from 'pages/SellerPage/hooks/useSellerPolicyInfo';
import { policiesInfoFields } from 'shared/constants/constants';
import { Loader } from 'shared/ui/Loader';
import { InfoTooltip } from 'shared/ui/Tooltip';

import { EditPoliciesModal } from './modal/EditPoliciesModal';

export const PoliciesInfoPage = memo(() => {
  const {
    handleSubmitPoliciesLink,
    handleSubmitPoliciesPdf,
    policiesData,
    isLoadingPolicyTab,
  } = useSellerPolicyInfo();
  const [isOpenPolicyModal, setIsOpenPolicyModal] = useState(false);
  const [editField, setEditField] = useState({ name: '', label: '' });
  const { t } = useTranslation();

  const showImgLabel = (link: string) => {
    if (link.match(/.pdf/)) {
      return <AttachFileOutlined sx={{ fontSize: 'inherit' }} />;
    } else {
      return <LaunchOutlined sx={{ fontSize: 'inherit' }} />;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        mt: '8px',
        padding: '16px',
        bgcolor: 'grey.300',
        borderRadius: '12px',
      }}
    >
      {!isLoadingPolicyTab ? (
        <>
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
              mt: '4px',
              mb: '16px',
            }}
          >
            <Typography typography="poppins.subtitle1">
              {t('Policies / Support')}
            </Typography>
            <InfoTooltip
              content={t(
                `Use this section to update your company's policies, to be shared with potential buyers. To share your policies, you may upload a PDF or provide a direct link/URL.`,
              )}
            />
          </Box>
          {policiesInfoFields.map((field) => (
            <Box key={field.name}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  '&:hover .editIconButton': {
                    visibility: 'visible',
                  },
                  borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                }}
                mb={2}
              >
                <Box
                  sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}
                >
                  <Typography color="#000000DE" typography="openSans.caption">
                    {field.label}
                  </Typography>
                  <Typography
                    sx={{ padding: '5px 0', color: '#00000080' }}
                    typography="openSans.subtitle1"
                  >
                    {policiesData?.[field?.name] ? (
                      <Link
                        color="primary.500"
                        underline="always"
                        target="_blank"
                        rel="noopener"
                        href={policiesData?.[field?.name]}
                      >
                        <Box
                          component="span"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '4px',
                            fontSize: '16px',
                            ml: '8px',
                          }}
                        >
                          <CircleRounded sx={{ fontSize: '4px' }} />
                          {field?.label}
                          {showImgLabel(policiesData?.[field?.name])}
                        </Box>
                      </Link>
                    ) : (
                      t('No information provided')
                    )}
                  </Typography>
                </Box>
                <IconButton
                  sx={{
                    visibility: 'hidden',
                  }}
                  className="editIconButton"
                  onClick={() => {
                    setEditField(field);
                    setIsOpenPolicyModal(true);
                  }}
                >
                  <ModeEditOutlineOutlined color="primary" />
                </IconButton>
              </Box>
            </Box>
          ))}
        </>
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loader size={100} />
        </Box>
      )}
      {isOpenPolicyModal && (
        <EditPoliciesModal
          titleModal={editField.label}
          fieldValue={editField.name}
          isModalOpen={isOpenPolicyModal}
          handleCloseModal={() => setIsOpenPolicyModal(false)}
          approveNameButton={t('Save')}
          handleSubmitPoliciesLink={handleSubmitPoliciesLink}
          handleSubmitPoliciesPdf={handleSubmitPoliciesPdf}
        />
      )}
    </Box>
  );
});
