import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { SellersService } from 'app/providers/api/services';
import { useApiMutation } from 'shared/hooks/useApiMutation';
import { useApiQuery } from 'shared/hooks/useApiQuery';
import { SellerInvitedUser } from 'shared/types/Sellers';

export default () => {
  const { sellerId } = useParams();
  const [listInvitedUsers, setListInvitedUsers] = useState<
    SellerInvitedUser[] | null
  >(null);

  const { isLoading: isLoadingInvitedUsers, refetch: refetchInvitedUsers } =
    useApiQuery(
      ['getSellerInvitedUsers', sellerId],
      () => SellersService.fetchSellerInvitedUsers(sellerId),
      {
        onSuccess: (data) => {
          setListInvitedUsers(data?.data);
        },
        cacheTime: 0,
      },
    );

  const { mutate: sellerSendInviteUsers } = useApiMutation(
    ['sellerSendInviteUsers', sellerId],
    SellersService.sellerSendInviteUsers,
    { onSuccess: () => refetchInvitedUsers() },
  );

  return {
    sellerId,
    sellerSendInviteUsers,
    listInvitedUsers,
    isLoadingInvitedUsers,
  };
};
