import axios from 'axios';
import { toast } from 'react-toastify';

import { USER_ACCESS_TOKEN } from 'shared/constants/localstorage';
import {
  convertKeysToCamelCase,
  convertKeysToSnakeCase,
} from 'shared/utils/converRequestAndresponseData';

const apiUrl =
  process.env.REACT_APP_SELLER_API ||
  `${window.location.protocol}//api.${window.location.hostname}`;

export const $api = axios.create({
  baseURL: apiUrl,
});

$api.interceptors.request.use((config) => {
  const newConfig = { ...config };
  newConfig.url = config.url;

  if (newConfig.headers) {
    newConfig.headers.Authorization = `Bearer ${
      localStorage.getItem(USER_ACCESS_TOKEN) || ''
    }`;
  }

  if (config.data && !(config.data instanceof FormData)) {
    newConfig.data = convertKeysToSnakeCase(config.data);
  }

  if (config.params) {
    newConfig.params = convertKeysToSnakeCase(config.params);
  }

  return newConfig;
});

$api.interceptors.response.use(
  (response) => {
    if (response.data) {
      response.data = convertKeysToCamelCase(response.data);
    }

    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      toast('Session expired');
    }

    return Promise.reject(error);
  },
);

export default $api;
