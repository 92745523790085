import { AttachFile, DeleteOutlineOutlined } from '@mui/icons-material';
import {
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useRef, useState } from 'react';

import { Modal } from 'shared/ui/Modal';
import { validateURL } from 'shared/utils/helpers';

interface EditPoliciesModalProps {
  titleModal: string;
  fieldValue: string;
  isModalOpen: boolean;
  handleCloseModal?: () => void;
  approveNameButton: string;
  handleSubmitPoliciesPdf?: (file: File, policyType: string) => void;
  handleSubmitPoliciesLink?: (link: string, policyType: string) => void;
}

export const EditPoliciesModal = ({
  titleModal,
  fieldValue,
  isModalOpen,
  handleCloseModal,
  approveNameButton,
  handleSubmitPoliciesPdf,
  handleSubmitPoliciesLink,
}: EditPoliciesModalProps) => {
  const fileInputRef = useRef(null);
  const [typeForUploading, setTypeForUploading] = useState('file');
  const [file, setFile] = useState<File | null>(null);
  const [link, setLink] = useState<string>('');
  const [helperText, setHelperText] = useState('');
  const [errorText, setErrorText] = useState('');

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const handleTextFieldClick = () => {
    setErrorText('');
    fileInputRef.current?.click();
  };

  const handleChangeOption = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setErrorText('');
    setTypeForUploading(target.value);
  };

  const handleSubmitModal = useCallback(() => {
    if (
      (typeForUploading === 'file' && file) ||
      (typeForUploading === 'link' && link)
    ) {
      if (typeForUploading === 'file') {
        handleSubmitPoliciesPdf(file, fieldValue);
      } else {
        handleSubmitPoliciesLink(link, fieldValue);
      }
      handleCloseModal();
    } else {
      setErrorText('Please select the correct option or fill in the field');
    }
  }, [
    fieldValue,
    file,
    handleCloseModal,
    handleSubmitPoliciesLink,
    handleSubmitPoliciesPdf,
    link,
    typeForUploading,
  ]);

  const handleDeleteFile = (event: any) => {
    event.stopPropagation();
    setFile(null);
    fileInputRef.current.value = null;
  };

  const handleChangeLink = ({ target }: any) => {
    setErrorText('');
    const { value } = target;
    setLink(value);
    if (validateURL(value)) {
      setHelperText('');
    } else {
      setHelperText('Please enter a valid URL, e.g. https://example.com');
    }
  };

  return (
    <Modal
      disabled={!!helperText || !!errorText}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      handleSubmitModal={handleSubmitModal}
      approveNameButton={approveNameButton}
    >
      <Box sx={{ width: 400 }}>
        <Typography typography="poppins.subtitle2">{titleModal}</Typography>
        <FormControl sx={{ marginY: '10px', width: '100%' }}>
          <RadioGroup
            name="policies-radio-buttons-group"
            value={typeForUploading}
            onChange={handleChangeOption}
            sx={{ marginY: '5px' }}
          >
            <FormControlLabel
              value="file"
              control={<Radio />}
              label={
                <Typography typography="poppins.body2">
                  {`Please, select the file (PDF-only) to upload`}
                </Typography>
              }
              sx={{ mb: '4px' }}
            />
            <Box sx={{ mb: '12px' }}>
              <input
                type="file"
                accept="application/pdf"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileInputChange}
              />
              <TextField
                onFocus={() => setTypeForUploading('file')}
                color="secondary"
                sx={{
                  input: { cursor: 'pointer' },
                  '.MuiInputBase-input': {
                    color: file ? 'primary.500' : 'inherit',
                  },
                }}
                error={!!errorText}
                placeholder="Document"
                fullWidth
                label="Document"
                onClick={handleTextFieldClick}
                InputProps={{
                  readOnly: true,
                  startAdornment: file && (
                    <AttachFile sx={{ color: 'primary.500', marginRight: 1 }} />
                  ),
                  endAdornment: file && (
                    <IconButton aria-label="delete" onClick={handleDeleteFile}>
                      <DeleteOutlineOutlined sx={{ color: 'primary.main' }} />
                    </IconButton>
                  ),
                }}
                value={file ? file.name : ''}
              />
            </Box>
            <FormControlLabel
              value="link"
              control={<Radio />}
              label={
                <Typography typography="poppins.body2">
                  {`or provide the URL link to the policy`}
                </Typography>
              }
              sx={{ mb: '4px' }}
            />
            <TextField
              onFocus={() => setTypeForUploading('link')}
              color="secondary"
              error={!!helperText || !!errorText}
              helperText={helperText}
              placeholder={'https://example.com'}
              fullWidth
              label={'URL link to policy'}
              onChange={handleChangeLink}
            />
          </RadioGroup>
        </FormControl>
        <Typography typography="openSans.body2" color="error">
          {errorText}
        </Typography>
      </Box>
    </Modal>
  );
};
