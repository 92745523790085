import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { toast } from 'react-toastify';

import { AuthService } from 'shared/config/oidcClient/authServices';
import { USER_ACCESS_TOKEN } from 'shared/constants/localstorage';
import { getMessage } from 'shared/utils/errors';

export const useErrors = (): {
  handleErrors: (error: AxiosError) => void;
} => {
  const handleErrors = useCallback((error: AxiosError) => {
    toast.error(getMessage(error));
    if (error.response?.status === 401) {
      const authService = new AuthService();
      localStorage.removeItem(USER_ACCESS_TOKEN);
      authService.logout();
    }
  }, []);

  return {
    handleErrors,
  };
};
