import { Box } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthService } from 'shared/config/oidcClient/authServices';
import {
  getRouteCompanySellerPage,
  getRouteSellers,
} from 'shared/constants/routerConst';
import useAuth from 'shared/hooks/useAuth';

export const AuthRedirectPage = () => {
  const navigate = useNavigate();
  const { login } = useAuth();

  const onRedirect = useCallback(async () => {
    const redirectRoute = getRouteSellers();

    try {
      const authService = new AuthService();
      const user = await authService.loginCallback();

      if (user) {
        const invitedSellerId = await login(user);
        if (invitedSellerId) {
          navigate(getRouteCompanySellerPage(invitedSellerId));
        } else {
          navigate(redirectRoute);
        }
      }
    } catch (error) {
      navigate(redirectRoute);
    }
  }, [login, navigate]);

  useEffect(() => {
    onRedirect();
  }, [onRedirect]);

  return <Box />;
};
