import { useCallback, useState } from 'react';

import { UserService } from 'app/providers/api/services';
import { AuthService } from 'shared/config/oidcClient/authServices';
import { USER_ACCESS_TOKEN } from 'shared/constants/localstorage';
import { UserRoleByIb } from 'shared/constants/userRole';
import { useApiMutation } from 'shared/hooks/useApiMutation';
import { User } from 'shared/types/User';

function useProvideAuth() {
  const [authenticated, setAuthenticated] = useState(false);
  const [isLoading, setIsloading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [isInited, setIsInited] = useState(false);
  const [userRoles, setUserRoles] = useState(null);
  const [invitedSellerId, setInvitedSellerId] = useState('');

  const { mutateAsync: getInvitedSellerId } = useApiMutation(
    ['getInvitedSellerId'],
    () => UserService.fetchSellerId(),
    {
      onSuccess: ({ sellerId }) => {
        setInvitedSellerId(sellerId);
        return sellerId;
      },
    },
  );

  const logout = useCallback(async () => {
    const authService = new AuthService();
    localStorage.removeItem(USER_ACCESS_TOKEN);
    setUserData(null);
    setUserRoles([]);
    setAuthenticated(false);
    authService.logout();
  }, []);

  const login = useCallback(
    async (user: User) => {
      localStorage.setItem(USER_ACCESS_TOKEN, user.access_token);
      const rolesIds = Object.keys(
        user?.profile['urn:zitadel:iam:org:project:roles'],
      );
      const roles = rolesIds.reduce((acc: Array<string>, id: string) => {
        if (id in UserRoleByIb) {
          acc.push(UserRoleByIb[id]);
        }
        return acc;
      }, []);
      let invitedSellerId = '';
      if (roles[0] === 'Seller Admin') {
        const { sellerId } = await getInvitedSellerId(null);
        invitedSellerId = sellerId;
      }

      setUserData({ ...user?.profile, roles: roles });
      setUserRoles(roles);
      setAuthenticated(true);
      return invitedSellerId;
    },
    [getInvitedSellerId],
  );

  return {
    authenticated,
    setAuthenticated,
    userData,
    setUserData,
    logout,
    login,
    isLoading,
    setIsloading,
    userRoles,
    setUserRoles,
    isInited,
    setIsInited,
    invitedSellerId,
  };
}

export default useProvideAuth;
